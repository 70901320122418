import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalService } from '../../global.service';
import { NavParams} from '@ionic/angular';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.page.html',
  styleUrls: ['./datepicker.page.scss'],
})
export class DatepickerPage implements OnInit {

  public stunden=0;
  public minuten=0;
  public sekunden=0
  public wert="";

  public isStunden=true;
  public isMinuten=true;
  public isSekunden=true;

  constructor(public navParams : NavParams, public global: GlobalService, public modalCtrl : ModalController) { }

  ngOnInit() {
    this.wert=(this.navParams.get('value'));
    this.isStunden=this.navParams.get('isStunden');
    this.isMinuten=this.navParams.get('isMinuten');
    this.isSekunden=this.navParams.get('isSekunden');

    //In Stunden und Minuten und Sekunden zerlegen
    let cp=this.wert.split(":");
    if (this.isStunden) {
      this.stunden=Number(cp[0]);
      this.minuten=Number(cp[1]);

      if (cp[2]) this.sekunden=Number(cp[2]);
        else this.sekunden=0;
    } else {
      this.minuten=Number(cp[0]);
      this.sekunden=Number(cp[1]);
    }

    this.ausgeben();


  }

  ausgeben() {
    

      if (this.isStunden==true) {
        this.wert=this.global.stdminsek(this.stunden*60*60+this.minuten*60+this.sekunden);
      }
      if (this.isMinuten==true && this.isStunden==false) {
        this.wert=this.global.stdmin(this.minuten*60+this.sekunden);
      }

      if (this.isSekunden==false) {
        this.wert=this.global.stdmin(this.stunden*60+this.minuten);
      }

  }


  addStunden(add) {
    this.stunden=this.stunden+add;
    if (this.stunden > 23) this.stunden=23;
    if (this.stunden < 0) this.stunden=0;
    this.ausgeben();
  }
  addMinuten(add) {
    this.minuten=this.minuten+add;
    if (this.minuten > 59) this.minuten=59;
    if (this.minuten < 0) this.minuten=0;
    this.ausgeben();
  }
  addSekunden(add) {
    this.sekunden=this.sekunden+add;
    if (this.sekunden > 59) this.sekunden=59;
    if (this.sekunden < 0) this.sekunden=0;
    this.ausgeben();
  }

  async sendBack() {
    /*
    if (this.isStunden==true) {
      this.wert=this.global.stdminsek(this.stunden*60*60+this.minuten*60+this.sekunden);
    }
    if (this.isMinuten==true && this.isStunden==false) {
      this.wert=this.global.stdmin(this.minuten*60+this.sekunden);
    }
*/


    let data = {'ergebnis' : this.wert};
    await this.modalCtrl.dismiss(data);
  }






}
